import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Autocomplete, Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { FileDownload } from "@mui/icons-material";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import RestClient from "services/RestClient";
import { showToastMessage } from "helpers/toastHelper";

function ClickReportsPage() {
    const { id } = useParams();
    const location = useLocation();
    const { rowData } = location.state || {}

    const [clicksReportData, setClicksReportData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isDownloadingReport, setIsDownloadingReport] = useState(false);

    const getClicksData = async () => {
        setIsLoadingData(true);
        const result = await RestClient.get(`/api/campaign/vehicles/metrics/${id}`);
        if (result?.data) {
            setClicksReportData(result?.data);
            if (result.data?.length === 0) {
                showToastMessage("No data available", "error");
            }
        } else if (result?.status !== 200) {
            showToastMessage(result?.message, "error");
        }
        setIsLoadingData(false);
    };

    const downloadReport = async () => {
        setIsDownloadingReport(true);
        const currentDate = new Date().toISOString().slice(0, 10);
        const fileName = `report_${currentDate}.xlsx`;
        const blob = await RestClient.getBlob(`/api/campaign/vehicles/report/${id}`);
        if (blob && !blob.message) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else if (blob?.status !== 200) {
            showToastMessage(blob?.message, "error");
        }
        setIsDownloadingReport(false);
    };

    useEffect(() => {
        if (id) {
            getClicksData();
        }
    }, [id]);

    return (
        <Container sx={{ mt: 6, mb: 6 }}>
            <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
                <Breadcrumbs routes={[
                    { label: "Home", route: "/campaigns" },
                    { label: "Report" },
                ]} />
            </MKBox>
            <MKTypography variant="h3" sx={{ mb: 3, mt: 2 }}>
                {(rowData?.Dealer || rowData?.dealer) || "Unknown Dealer"}
            </MKTypography>
            <Grid container>
                <Grid item xs={12} md={9} alignContent="start">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} alignContent="start">
                            <MKTypography variant="body">
                                Campaign Start: {(rowData?.["Start Date"] || rowData?.startdate) || "Unknown"}
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={6} alignContent="start">
                            <MKTypography variant="body">
                                Campaign End: {(rowData?.["End Date"] || rowData?.enddate) || "Unknown"}
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <>
                                {isLoadingData ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        Report is loading... <CircularProgress />
                                    </div>
                                ) : (
                                    <TableContainer sx={{ my: 3, borderRadius: 1 }}>
                                        <Table>
                                            <TableHead sx={{
                                                display: "table-header-group", "& th": {
                                                    color: "#1f2020",
                                                    backgroundColor: "#f0f2f5"
                                                }
                                            }}>
                                                <TableRow>
                                                    <TableCell>
                                                        Vehicle
                                                    </TableCell>
                                                    <TableCell>
                                                        Clicks
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {clicksReportData?.length > 0 ? (
                                                    <>
                                                        {clicksReportData.map((data, key) => (
                                                            <TableRow hover>
                                                                <TableCell>
                                                                    {data?.vehicleName}
                                                                </TableCell>
                                                                <TableCell>{data?.clicks}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={3}>
                                                            No rows
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <MKBox sx={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}>
                                <MKButton
                                    color="secondary"
                                    disabled={isDownloadingReport}
                                    endIcon={isDownloadingReport ? <CircularProgress color="inherit" size={20} /> : <FileDownload />}
                                    onClick={downloadReport}
                                >
                                    {isDownloadingReport ? "Downloading..." : "Download Report"}
                                </MKButton>
                            </MKBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    );
}

export default ClickReportsPage;