import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from "examples/Breadcrumbs";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { Card, Grid, CircularProgress, Container } from "@mui/material";
import CampaignInfo from "components/campaigns/CampaignInfo"
import RestClient from 'services/RestClient';

function CampaignDetailPage() {
	const { id } = useParams();
	const [CampaignData, setCampaignData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchCampaignData = async () => {
		const result = await RestClient.get(`/api/campaigns/campaign/${id}`);
		if (result?.data[0]) {
			setCampaignData(result.data[0]);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (id) {
			fetchCampaignData();
		}
	}, [id]);

	return (
		<Container sx={{ mt: 6, mb: 6 }}>
			{isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					Loading Campaign data... <CircularProgress />
				</div>
			) : (
				<>
					<MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
						<Breadcrumbs routes={[
							{ label: "Dealers", route: "/dealers" },
							{ label: "Dealer", route: "/dealers/dealer/" + CampaignData.dealer_id },
							{ label: "Campaigns", route: "/campaigns" },
							{ label: "Campaign" },
						]} />
					</MKBox>
					<MKTypography variant="h3" mb={1}>
						{CampaignData.dealer}
					</MKTypography>
					<CampaignInfo campaign={CampaignData} />
				</>
			)}
		</Container>
	);
}

export default CampaignDetailPage