import React, { useState, useEffect } from 'react';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import Targetting from './targetting';
import { Tab, Tabs, Grid, CircularProgress, Box, Alert, Tooltip, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { styled } from '@mui/material/styles';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { FileDownload, InfoOutlined, ArrowForwardIosSharp } from '@mui/icons-material';
import RobotResultsView from 'components/robots/resultView';
import EmailBannerModal from './EmailBannerModal';
import RenewCampaignModal from './RenewCampaignModal';
import CampaignsDetailBannerMetrics from './CampaignsDetailBannerMetrics';
import { showToastMessage } from '../../helpers/toastHelper';
import RestClient from 'services/RestClient';
import { validateQueryString, validateVariableInput } from 'helpers/helpers';
import TemplateVariablesForm from 'pages/Templates/Components/TemplateVariablesForm';
import BannerIFrame from './BannerIFrame';
import DeleteCampaignModal from './DeleteCampaignModal';
import { useRole } from "../../context/RoleContext";

function CampaignDetailForm({ campaign }) {
	const { roles } = useRole();
	const [activeTab, setActiveTab] = useState(1);
	const handleTabType = (event, newValue) => setActiveTab(newValue);
	const [setTargetting, setSetTargetting] = useState(false);
	const [lineItemError, setLineItemError] = useState(false);
	const [formData, setFormData] = useState({ ...campaign });
	const [isUpdating, setIsUpdating] = useState(false);
	const [vehiclesData, setVehiclesData] = useState([]);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [isDownloadingBanner, setIsDownloadingBanner] = useState(false);
	const [isPublishingGAM, setIsPublishingGAM] = useState(false);
	const [campaignExpired, setCampaignExpired] = useState(false);
	const [isGamDisabled, setIsGamDisabled] = useState(false);
	const [disableUpdateButton, setDisableUpdateButton] = useState(false); //set to "true" to get the Update button disabled on page load
	const [lastRefreshedDate, setLastRefreshedDate] = useState("");
	const [queryStringError, setQueryStringError] = useState(false);
	const [rows, setRows] = useState([]);

	const currentDate = new Date().toISOString().replace(/T.*/, '')

	const updateCampaign = async (event) => {
		event.preventDefault();
		let payload = {};
		if (queryStringError) {
			payload = { ...formData, query_string: "", line_items: [formData.line_items.line_item_id] }
		} else {
			payload = { ...formData, line_items: [formData.line_items.line_item_id] };
		}
		setIsUpdating(true);
		const url = `/api/campaigns/update/${formData.id}`;
		const result = await RestClient.put(url, payload);
		if (result.status === 200) {
			window.location.reload();
		} else {
			showToastMessage(result?.message, 'error');
		}
		setIsUpdating(false);
	};

	const handlePublishToGAM = async (event) => {
		event.preventDefault();
		setIsPublishingGAM(true);
		const result = await RestClient.post(`/api/campaigns/gam/${formData.id}`, {});
		if (result.status === 200) {
			showToastMessage(result?.message, 'success');
			window.location.reload();
		} else {
			showToastMessage(result?.message, 'error');
		}
		setIsPublishingGAM(false);
	};

	const onRefreshResults = async () => {
		setIsRefreshing(true);
		const json = await RestClient.get(`/api/robots/robot/${formData.robot}/campaign/${formData.id}/refreshresults`);
		if (json) {
			setVehiclesData(json?.data);
			if (json.data[0]?.Updated) {
				setLastRefreshedDate(json.data[0]?.Updated);
			}
		}
		setIsRefreshing(false);
	}

	const downloadBanners = async () => {
		setIsDownloadingBanner(true);
		let dealerName = formData.dealer || 'Unknown_Dealer';
		dealerName = dealerName.replace(/\s+/g, '_');
		const currentDate = new Date().toISOString().slice(0, 10);
		const fileName = `${dealerName}_${currentDate}_banners.zip`;
		const blob = await RestClient.getBlob(`/api/campaigns/campaign/${formData.id}/download`);
		if (blob && !blob.message) {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}
		setIsDownloadingBanner(false);
	};

	const handleSelectedItemsChange = (selectedIds) => {
		setFormData({ ...formData, vehicles_ids: selectedIds })
	}

	const handleQueryString = (e) => {
		setQueryStringError(false);
		const value = e.target.value;
		setFormData({ ...formData, query_string: e.target.value });
		if (!validateQueryString(value) && value !== "") {
			setQueryStringError(!validateQueryString(value));
		}
	};

	const updateCustomVariables = (variables) => {
		const formattedData = variables.map(row => ({
			id: row.id,
			variable_value: validateVariableInput(row.variable_value, row.custom_variables_type_id) ? row.variable_value : '',
			extension: (row.custom_variables_type_id === 3 && row.file_name) ? row.file_name.split('.').pop() : false
		}));
		setFormData({ ...formData, custom_variables: formattedData });
	};

	const CustomAccordionSummary = styled((props) => (
		<AccordionSummary
			expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}));

	const extractCreativeIds = (lineItems) => {
		for (const key in lineItems) {
			if (lineItems.hasOwnProperty(key)) {
				return lineItems[key].creative_ids;
			}
		}
		return "";
	};
	const extractLineIds = (lineItems) => {
		for (const key in lineItems) {
			if (lineItems.hasOwnProperty(key)) {
				return lineItems[key].line_item_id;
			}
		}
		return "";
	};

	useEffect(() => {
		if (campaign?.dealer) {
			const initialValues = campaign;
			const gamIds = {
				line_item_id: extractLineIds(initialValues.line_items),
				creative_ids: extractCreativeIds(initialValues.line_items)
			};
			if (!gamIds?.line_item_id || !initialValues.gam_campaign_id) {
				setIsGamDisabled(true);
			}
			let variablesArray = [];
			if (typeof initialValues.custom_variables === 'object') {
				variablesArray = Object.values(initialValues.custom_variables);
				if (variablesArray.length > 0) {
					setFormData({ ...formData, dealer: campaign.dealer, dealer_id: campaign.dealer_id, custom_variables: variablesArray, line_items: gamIds });
					setRows(variablesArray);
				} else {
					setFormData({ ...formData, dealer: campaign.dealer, dealer_id: campaign.dealer_id, custom_variables: [], line_items: gamIds });
					setRows([]);
				}
			}

		} else {
			setFormData({ ...formData, dealer: null, dealer_id: null })
		}
	}, [campaign]);

	useEffect(() => {
		if (formData.enddate && formData.id !== null) {
			if (formData.enddate < currentDate) {
				setCampaignExpired(true);
			}
		}
	}, [formData.enddate]);

	return (
		<MKBox width="100%" >
			<MKBox p={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<MKTypography sx={{
							fontSize: 14,
							marginTop: 1.5
						}} variant="h5">Campaign ID: {formData.id}</MKTypography>
					</Grid>
					<Grid item xs={12} md={6}>
						<MKBox display="flex" justifyContent="flex-end">
							<RenewCampaignModal startdate={formData.startdate} campaignID={formData.id} isCampaignExpired={campaignExpired} />
							{roles.includes("Super-Admin") && (
								<div style={{ marginLeft: "10px" }}>
									<DeleteCampaignModal campaignID={formData.id} />
								</div>
							)}
						</MKBox>
					</Grid>
					{campaignExpired ? (
						<Grid item xs={12} md={12}>
							<Alert
								severity="error">Campaign is expired</Alert>
						</Grid>
					) : null}
					<Grid item xs={12} md={6}>
						<MKInput variant="standard" disabled={true} label="Dealer" fullWidth
							value={formData.dealer} />
					</Grid>
					<Grid item xs={12} md={6}>
						<MKInput variant="standard" disabled={true} label="Date Requested" fullWidth value={formData.requested} onChange={(e) => setFormData({ ...formData, requested: e.target.value })} />
					</Grid>
					<Grid item xs={12} md={6}>
						<MKInput
							variant="standard"
							disabled
							label="Start Date"
							fullWidth
							InputLabelProps={{ shrink: true }}
							value={formData.startdate}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<MKInput
							variant="standard"
							disabled
							label="End Date"
							fullWidth
							InputLabelProps={{ shrink: true }}
							value={formData.enddate}
						/>
					</Grid>
					<Grid item xs={12}>
						<MKInput variant="standard" type="string" label="GAM Campaign" fullWidth value={formData.gam_campaign_id}
							onChange={(e) => setFormData({ ...formData, gam_campaign_id: e.target.value })} />
					</Grid>

					<Grid item xs={12} md={6}>
						<MKInput variant="standard" error={lineItemError} label="GAM LineItem ID" fullWidth rows={6}
							value={formData?.line_items?.line_item_id ?
								formData?.line_items?.line_item_id : ""}
							onChange={(e) => {
								if (formData.line_items) {
									setFormData({
										...formData,
										line_items: {
											...formData.line_items, line_item_id: e.target.value
										}
									})
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<MKInput variant="standard" label="GAM Creative ID" disabled fullWidth rows={6}
							value={formData.line_items?.creative_ids ?
								formData.line_items?.creative_ids : ""} />
					</Grid>
					<Grid item xs={12} md={12}>
						<MKInput variant="standard" label="Template Suite" fullWidth value={formData.template_suite?.name} disabled />
					</Grid>
					<Grid item xs={12} md={12}>
						<Accordion sx={{
							boxShadow: "none",
							"&:before": {
								display: "none",
							},
						}}
						>
							<CustomAccordionSummary
								aria-controls="panel1-content"
								id="panel1-header"
							>
								<MKTypography variant="h6" color="secondary">Campaign Variables</MKTypography>
							</CustomAccordionSummary>
							<AccordionDetails sx={{ fontSize: '15px', p: 0 }}>
								<Grid container spacing={2}>
									{rows.length > 0 ? (
										<TemplateVariablesForm
											rows={rows}
											setRows={setRows}
											updateFormData={updateCustomVariables}
											editable={false} />
									) : (
										<MKTypography variant="body2" color="secondary">No Custom Variables available</MKTypography>
									)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
					<Grid item xs={12} md={6}>
						<MKInput variant="standard" label="Sales Rep" fullWidth rows={6} value={formData.sales_rep} onChange={(e) => {
							setFormData({ ...formData, sales_rep: e.target.value });
							setDisableUpdateButton(false);
						}} />
					</Grid>
					<Grid item xs={12} md={6}>
						<MKInput variant="standard" label="Notes" fullWidth rows={6} value={formData.notes} onChange={(e) => {
							setFormData({ ...formData, notes: e.target.value });
							setDisableUpdateButton(false);
						}} />
					</Grid>

					<Grid item xs={12} md={12}>
						<MKInput variant="standard"
							label={<div style={{ display: "flex", alignItems: "center" }}>
								Query String Suffix
								<Tooltip
									title="Use this field to add tracking parameters to your URLs. Query string
suffixes help you monitor the effectiveness of your campaigns by
capturing details like source, medium, and campaign name.">
									<InfoOutlined fontSize="small" sx={{ ml: 1 }}></InfoOutlined>
								</Tooltip>
							</div>}
							fullWidth value={formData.query_string}
							onChange={handleQueryString}
							error={queryStringError}
							helperText={queryStringError ? "Invalid query string format" : ""}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CampaignsDetailBannerMetrics bannerMetricsData={formData?.stats} campaignData={formData} />
					</Grid>
				</Grid>
				<MKBox >
					{setTargetting ? (
						<Targetting campaign={formData.id} />
					) : (
						<></>
					)}
					<Tabs value={activeTab} onChange={handleTabType}>
						<Tab
							icon={
								<MKBox
									component="i"
									color="dark"
									mr={1.25}
									sx={{ fontSize: ({ typography: { size } }) => size.sm }}
									className="fas fa-desktop"
								/>
							}
							label="Vehicles"
							value={1}
						/>
						<Tab
							icon={
								<DriveEtaIcon
									fontSize="small"
									color="dark"
									mr={1.25}
									sx={{ fontSize: ({ typography: { size } }) => size.sm }}
								/>
							}
							label="Banners Preview"
							value={2}
						/>
					</Tabs>
					{isUpdating ? (
						<Box sx={{ display: 'flex' }}>
							<CircularProgress />
						</Box>
					) : (
						<>
							<MKBox width="100%" lg={6} xs={12} display={activeTab === 1 ? "block" : "none"}>
								<RobotResultsView
									vehiclesData={vehiclesData}
									refreshresults={onRefreshResults}
									campaignID={formData.id}
									isUpdating={isUpdating}
									onRefreshing={isRefreshing}
									onUpdateCampaign={updateCampaign}
									disableUpdateButton={disableUpdateButton}
									lastRefreshedDate={lastRefreshedDate}
									onSelectedItemsChange={handleSelectedItemsChange} />
							</MKBox>
							<MKBox width="100%" lg={6} xs={12} display={activeTab === 2 ? "block" : "none"}>
								<Grid container item justifyContent="end" xs={12} my={2} spacing={1}>
									<Grid item xs={2}>
										<MKButton
											variant="gradient"
											color="secondary"
											disabled={isDownloadingBanner || campaignExpired}
											endIcon={isDownloadingBanner ? <CircularProgress color="inherit" size={20} /> : <FileDownload />}
											onClick={downloadBanners}>
											{isDownloadingBanner ? "Downloading Banners..." : "Download Banners"}
										</MKButton>
									</Grid>
									<Grid item xs={2}>
										<MKButton
											variant="gradient"
											color="secondary"
											fullWidth
											disabled={isPublishingGAM || isGamDisabled}
											endIcon={isPublishingGAM ? <CircularProgress color="inherit" size={20} /> : null}
											onClick={handlePublishToGAM}>
											{isPublishingGAM ? "Publishing..." : "Publish to GAM"}
										</MKButton>
									</Grid>
									<Grid item xs={2}>
										<EmailBannerModal campaignID={formData.id} />
									</Grid>
								</Grid>

								{window.location.origin.includes("localhost") ? (
									<></>
								) : (
									<>
										{formData?.banners && formData?.banners?.length > 0 && (
											formData.banners?.map((link, index) => (
												<BannerIFrame key={index} link={link} />
											))
										)}
									</>
								)}
							</MKBox>
						</>
					)}
				</MKBox>
			</MKBox>
		</MKBox>
	);
}

export default CampaignDetailForm;