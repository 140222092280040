import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

function CampaignsDetailBannerMetrics({ bannerMetricsData, campaignData }) {
    const [bannerSizes, setBannerSizes] = useState([]);

    useEffect(() => {
        if (bannerMetricsData) {
            setBannerSizes(Object.keys(bannerMetricsData));
        }
    }, [bannerMetricsData]);

    return (
        <TableContainer sx={{ my: 3, borderRadius: 1 }}>
            <Table>
                <TableHead sx={{
                    display: "table-header-group", "& th": {
                        color: "#1f2020",
                        backgroundColor: "#f0f2f5"
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            Banner Size
                        </TableCell>
                        <TableCell>
                            Views
                        </TableCell>
                        <TableCell>
                            Clicks
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bannerSizes?.length > 0 ? (
                        <>
                            {bannerSizes.map((size) => (
                                <TableRow hover>
                                    <TableCell>
                                        {size}
                                    </TableCell>
                                    <TableCell>
                                        {bannerMetricsData[size]?.views}
                                    </TableCell>
                                    <TableCell>
                                        <Link style={{color: 'rgb(0, 102, 204)'}} to={`/report/${campaignData?.id}`} state={{ rowData: campaignData }}>
                                            {bannerMetricsData[size]?.clicks}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow>
                            <TableCell align='center' colSpan={3}>
                                No rows
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CampaignsDetailBannerMetrics;